import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDataset } from '../../../../types/project/datasets';

export type DatasetsState = {
  loading: boolean;
  hasErrors: boolean;
  fetching: boolean;
  currentPage: number;
  totalCount: number | undefined;
  data: IDataset[];
};
//
export const initialState: DatasetsState = {
  loading: false,
  hasErrors: false,
  fetching: false,
  currentPage: 1,
  totalCount: undefined,
  data: [],
};
// A slice
export const datasetSlice = createSlice({
  name: 'projectDatasets',
  initialState,
  reducers: {
    setDatasets: (state, action: PayloadAction<any>) => {
      action.payload.forEach((dataset: IDataset) => {
        dataset.checked = false;
      });
      state.data = [...state.data, ...action.payload];
    },
    startLoading: (state) => {
      state.loading = true;
    },
    finishLoading: (state) => {
      state.loading = false;
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },
    setCheckAllDatasets: (state, action: PayloadAction<boolean>) => {
      state.data.forEach((key: IDataset) => {
        key.checked = action.payload;
      });
    },
    setCheckDataset: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.data.forEach((key: IDataset) => {
        if (key.id === id) {
          key.checked = !key.checked;
        }
      });
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setErrors: (state, action: PayloadAction<boolean>) => {
      state.hasErrors = action.payload;
    },
    clearData: () => initialState,
  },
});

const projectDatasetReducer = datasetSlice.reducer;
export default projectDatasetReducer;
