import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInfrastructure } from '../../../../types/project/infrastructure';

export type InfrastructureState = {
  loading: boolean;
  hasErrors: boolean;
  fetching: boolean;
  currentPage: number;
  totalCount: number | undefined;
  data: IInfrastructure[];
};
//
export const initialState: InfrastructureState = {
  loading: false,
  hasErrors: false,
  fetching: false,
  currentPage: 1,
  totalCount: undefined,
  data: [],
};
// A slice
export const infrastructureSlice = createSlice({
  name: 'projectInfrastructure',
  initialState,
  reducers: {
    setInfrastructure: (state, action: PayloadAction<any>) => {
      action.payload.forEach((infrastructure: IInfrastructure) => {
        infrastructure.checked = false;
      });

      state.data = [...state.data, ...action.payload];
    },
    startLoading: (state) => {
      state.loading = true;
    },
    finishLoading: (state) => {
      state.loading = false;
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },
    setCheckAllInfrastructure: (state, action: PayloadAction<boolean>) => {
      state.data.forEach((item: IInfrastructure) => {
        item.checked = action.payload;
      });
    },
    setCheckInfrastructure: (state, action: PayloadAction<string>) => {
      const id = action.payload;

      state.data.forEach((item: IInfrastructure) => {
        if (item.id === id) {
          item.checked = !item.checked;
        }
      });
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setErrors: (state, action: PayloadAction<boolean>) => {
      state.hasErrors = action.payload;
    },
    clearData: () => initialState,
  },
});

const projectInfrastructureSliceReducer = infrastructureSlice.reducer;
export default projectInfrastructureSliceReducer;
