/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import {
  Typography, Avatar, CardHeader, CardContent, Card,
} from '@mui/material';

import { Comment } from '../../Pages/Projects/ProjectPages/DailyUpdates/types';
import { getFormattedDateFromTimeStamp } from '../../core/helpers/dateMethods';
import { env } from '../../env';

interface Props {
  comment: Comment;
}

export function CommentCard({ comment }: Props) {
  const {
    userInfo,
    text,
    updatedDate,
  } = comment;

  return (
    <Card sx={{
      maxWidth: 1100, width: '100%', backgroundColor: '#333333', marginBottom: '10px',
    }}
    >
      <CardHeader
        avatar={(
          <Avatar
            alt={userInfo?.name}
            src={`${env.REACT_APP_API_URL}${userInfo?.image}`}
          />
            )}
        title={userInfo?.name}
        subheader={getFormattedDateFromTimeStamp(updatedDate)}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
}
