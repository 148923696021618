/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Typography,
  Card,
  CardHeader,
  CardMedia, CardContent, CardActions, Collapse,
} from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CommentCard } from '../../../../components/CommentCard/CommentCard';
import { DailyUpdate } from './types';
import { useAppDispatch } from '../../../../core/redux/store';
import { env } from '../../../../env';
import { commentsRequest } from '../../../../core/redux/projects/feeds/actions';
import { getComments } from '../../../../core/redux/projects/feeds/selectors';
import { getFormattedDateFromTimeStamp } from '../../../../core/helpers/dateMethods';
import { StorageService } from '../../../../core/services/storage/Storage.service';

interface Props {
  id: string;
  post: DailyUpdate;
  addNewCommentHandler: (id: string) => void;
  projectId: string;
}

export function DailyUpdatesCard({
  id, post, addNewCommentHandler, projectId,
}: Props) {
  const dispatch = useAppDispatch();

  const comments = useSelector(getComments);

  const [expanded, setExpanded] = React.useState(false);
  const [dailyUpdateImageId, setDailyUpdateImageId] = React.useState('');

  const theme = useMemo(() => createTheme({
    palette: {
      mode: 'dark',
    },
  }), []);

  const {
    text, userInfo, createdDate,
  } = post;

  const fetchData = async () => {
    const res = await StorageService.getDailyUpdateImagesInfo(
      projectId,
      id,
    );

    setDailyUpdateImageId(res.data.dailyUpdateInfo.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleExpandClick = () => {
    dispatch(commentsRequest(id));
    setExpanded(!expanded);
  };

  interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }

  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
    // eslint-disable-next-line @typescript-eslint/no-shadow
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <ThemeProvider theme={theme}>

      <Card sx={{
        maxWidth: 1100, width: '100%', marginBottom: '10px', marginTop: '10px',
      }}
      >
        <CardHeader
          avatar={(
            <Avatar
              alt={userInfo?.name}
              src={`${env.REACT_APP_API_URL}${userInfo?.image}`}
            />
              )}
          title={userInfo?.name}
          subheader={getFormattedDateFromTimeStamp(createdDate)}
        />
        {dailyUpdateImageId && (
        <CardMedia
          component="img"
          height="60%"
          image={`${env.REACT_APP_API_STORAGE_URL}/attachment/daily-updates/${projectId}/${id}/${dailyUpdateImageId}`}
        />
        )}
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton onClick={() => addNewCommentHandler(id)}>
            <AddIcon />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {comments && comments.map((comment) => (
              <CommentCard
                key={comment.id}
                comment={comment}
              />
            ))}
          </CardContent>
        </Collapse>
      </Card>
    </ThemeProvider>
  );
}
