import React, { useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import s from '../Projects/Projects.module.scss';
import UserProfile from '../../components/UserProfile/UserProfile';
import { getUserInfo } from '../../core/redux/user/selectors';
import StorageSettings from './Storage/StorageSettings';

function Settings() {
  const user = useSelector(getUserInfo);
  const theme = useMemo(() => createTheme({
    palette: {
      mode: 'dark',
    },
  }), []);

  return (
    <ThemeProvider theme={theme}>
      <header>
        <div className={s.header_container}>
          <div className={s.title_container}>
            <div className={s.title_page}>
              {
                `Welcome ${user.name}, let's view your tokens information`
              }
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <UserProfile />
          </div>
        </div>
      </header>

      <StorageSettings />
    </ThemeProvider>
  );
}

export default Settings;
