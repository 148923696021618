/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from 'react';
import {
  Typography,
  Button,
  Box,
  FormControl,
  Modal,
  Stack,
  Checkbox, FormControlLabel, MenuItem, OutlinedInput, ListItemText, Select, SelectChangeEvent,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar, VariantType } from 'notistack';
import { ProjectService } from '../../../core/services/projects/Project.service';
import { StorageService } from '../../../core/services/storage/Storage.service';

interface Props {
  isModalTriggered: boolean;
  closeTriggered: () => void;
}

export function AddNewStorageSettingTokenModal({ isModalTriggered, closeTriggered }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const handleShowSnackBar = (variant: VariantType, text: string) => {
    enqueueSnackbar(text, {
      variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  const theme = useMemo(() => createTheme({
    palette: {
      mode: 'dark',
    },
  }), []);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [open, setOpen] = React.useState(false);
  const [projectsInfo, setProjectsInfo] = React.useState([] as { id: string; name: string }[]);
  const [expiresAt, setExpireAt] = React.useState(null as any);
  const [projectIds, setProjectIds] = React.useState<string[]>([]);
  const [needToExpire, setNeedToExpire] = React.useState(true);

  const getProjectsShortInfo = () => {
    ProjectService.getProjectsShortInfo()
      .then(({ data }) => {
        setProjectsInfo(data.projects);
      });
  };

  const clearForm = () => {
    setProjectIds([] as string[]);
    setExpireAt(null);
    setNeedToExpire(true);
  };

  useEffect(() => {
    setOpen(isModalTriggered);
    if (isModalTriggered) {
      getProjectsShortInfo();
    } else {
      clearForm();
    }
  }, [isModalTriggered]);

  const handleClose = () => {
    closeTriggered();
    setOpen(false);
  };

  const createNewTokenAction = () => {
    if (!projectIds.length) {
      handleShowSnackBar('error', 'Please select at least one project.');
      return;
    }

    if (!expiresAt) {
      handleShowSnackBar('error', 'Please select expire date.');
      return;
    }

    StorageService.createStorageSetting({
      projectIds,
      expiresAt,
      needToExpire,
    })
      .then(() => {
        handleShowSnackBar('success', 'New storage setting token created.');
        clearForm();
        closeTriggered();
      })
      .catch(() => {
        handleShowSnackBar('error', 'Error while creating new storage setting token.');
      });
  };

  const handleChange = (event: SelectChangeEvent<typeof projectIds>) => {
    const {
      target: { value },
    } = event;
    setProjectIds(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleNeedToExpired = () => {
    setNeedToExpire(!needToExpire);
  };

  const expireDateHandler = (date: any) => {
    setExpireAt(date);
  };

  const selectRender = (selected: string[]) => {
    if (selected.length === 0) {
      return <em>Choose projects</em>;
    }

    const names = selected.map((id) => projectsInfo.find((project) => project.id === id)?.name);

    return names.join(', ');
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography color={theme.palette.primary.light} id="modal-modal-title" variant="h6" component="h3">
          Add new storage token
        </Typography>

        <Stack direction="column" alignItems="stretch" spacing={2} sx={{ minWidth: 120, marginTop: '12px' }}>
          <FormControl>
            <Select
              multiple
              displayEmpty
              value={projectIds}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => selectRender(selected)}
              MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem disabled value="">
                <em>Choose projects</em>
              </MenuItem>
              {projectsInfo.map((project) => (
                <MenuItem key={project.name} value={project.id}>
                  <Checkbox checked={projectIds.indexOf(project.id) > -1} />
                  <ListItemText primary={project.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker onChange={(value) => expireDateHandler(value)} label="Choose expire date" />
          </LocalizationProvider>
          <FormControlLabel control={<Checkbox onChange={handleNeedToExpired} defaultChecked />} label="Need to expire" style={{ color: theme.palette.primary.light }} />

          <Button variant="contained" onClick={createNewTokenAction}>
            Create new token
          </Button>

        </Stack>
      </Box>
    </Modal>
  );
}
