import api from '../../api';
import { getAdditionalParams } from '../../helpers/apiHelpers';

export const DatasetService = {
  getProjectDatasets: (
    projectId: string,
    display?: boolean,
    page?: number,
    limit?: number,
    signal?: AbortSignal,
    cancelToken?: any,
  ) => api(
    'GET',
    [],
    `/view/projects/${projectId}/data?page=${page}&limit=${limit}`,
    {},
    signal,
    cancelToken,
  ),
  getDataset: (version: string, display?: boolean) => api('GET', [], `/data/${version}${getAdditionalParams(display)}`, {}),
};

// Project Datasets
// GET /projects/<project_version>/data
