import storageApi from '../../storageApi';

export const StorageService = {
  getMetricsForExperiment: (
    projectId: string,
    experimentId: string,
    signal?: AbortSignal,
  ) => storageApi(
    'GET',
    [],
    `/metrics/${projectId}/${experimentId}`,
    {},
    signal,
  ),
  getDataDriftForMonitoring: (
    projectId: string,
    monitoringId: string,
    signal?: AbortSignal,
  ) => storageApi(
    'GET',
    [],
    `/monitoring/${projectId}/${monitoringId}/DATA_DRIFT`,
    {},
    signal,
  ),
  getConceptDriftForMonitoring: (
    projectId: string,
    monitoringId: string,
    signal?: AbortSignal,
  ) => storageApi(
    'GET',
    [],
    `/monitoring/${projectId}/${monitoringId}/CONCEPT_DRIFT`,
    {},
    signal,
  ),
  getPlotsForExperiment: (
    projectId: string,
    experimentId: string,
    signal?: AbortSignal,
  ) => storageApi(
    'GET',
    [],
    `/plots/${projectId}/${experimentId}`,
    {},
    signal,
  ),
  getArtifactsForExperiment: (
    projectId: string,
    experimentId: string,
    signal?: AbortSignal,
  ) => storageApi(
    'GET',
    [],
    `/artifacts/${projectId}/${experimentId}`,
    {},
    signal,
  ),
  getModelsForExperiment: (
    projectId: string,
    experimentId: string,
    signal?: AbortSignal,
  ) => storageApi(
    'GET',
    [],
    `/models/${projectId}/${experimentId}`,
    {},
    signal,
  ),
  getDailyUpdateImagesInfo: (projectId: string, dailyUpdateId: string) => storageApi('GET', [], `/daily-updates/${projectId}/${dailyUpdateId}`),
  uploadProjectPhoto: (projectId: string, data: any) => storageApi('POST', data, `/attachment/project/${projectId}/general`, {
    'Content-Type': 'multipart/form-data',
  }),
  uploadUserAvatar: (data: any) => storageApi('POST', data, '/attachment/user/avatar', {
    'Content-Type': 'multipart/form-data',
  }),
  uploadDailyUpdateImage: (projectId: string, dailyUpdateId: string, data: any) => storageApi('POST', data, `/attachment/daily-updates/${projectId}/${dailyUpdateId}`, {
    'Content-Type': 'multipart/form-data',
  }),
  getStorageSettings: (
    signal?: AbortSignal,
  ): Promise<{
    id: string;
    token: string;
    isActive: boolean;
    needToExpire: boolean;
    expiresAt: string;
    projectsInfo: { id: string; name: string }[];
    createdDate: string;
    updatedDate: string;
  }[]
  > => storageApi(
    'GET',
    [],
    '/access-tokens/tokens',
    {},
    signal,
  ).then(({ data }) => data),
  getStorageSettingsByProjectId: (
    projectId: string,
    signal?: AbortSignal,
  ): Promise<{
    id: string;
    token: string;
    isActive: boolean;
    needToExpire: boolean;
    expiresAt: string;
    projectsInfo: { id: string; name: string }[];
    createdDate: string;
    updatedDate: string;
  }[]
  > => storageApi(
    'GET',
    [],
    `/access-tokens/tokens/project/${projectId}`,
    {},
    signal,
  ).then(({ data }) => data.tokens),
  getStorageSetting: (
    id: string,
    signal?: AbortSignal,
  ): Promise<{
    token: {
      id: string;
      token: string;
      isActive: boolean;
      needToExpire: boolean;
      expiresAt: string;
      projectsInfo: { id: string; name: string }[];
      createdDate: string;
      updatedDate: string;
    } }
  > => storageApi(
    'GET',
    [],
    `/access-tokens/tokens/${id}`,
    {},
    signal,
  ).then(({ data }) => data),
  createStorageSetting: (
    payload: {
      needToExpire: boolean;
      expiresAt: string;
      projectIds: string[];
    },
    signal?: AbortSignal,
  ): Promise<{
    settings: {
      id: string;
      token: string;
      isActive: boolean;
      needToExpire: boolean;
      expiresAt: string;
      projectsInfo: string[];
      createdDate: string;
      updatedDate: string;
    }[]
  }> => storageApi(
    'POST',
    {
      needToExpire: payload.needToExpire,
      expiresAt: payload.expiresAt,
      projectIds: payload.projectIds,
    },
    '/access-tokens/generate-token',
    {},
    signal,
  ),
};
