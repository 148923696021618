import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DailyUpdate } from '../../../../Pages/Projects/ProjectPages/DailyUpdates/types';

export type FeedState = {
  loading: boolean;
  hasErrors: boolean;
  data: DailyUpdate[];
  dailyUpdateCount: number;
  comments: {
    createdDate: string;
    id: string;
    text: string;
    updatedDate: string;
    userInfo: {
      image: string;
      name: string;
      email: string;
      id: string;
    }
  }[];
};
//
export const initialState: FeedState = {
  loading: false,
  hasErrors: false,
  data: [],
  dailyUpdateCount: 0,
  comments: [],
};
// A slice
export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    setDailyUpdateCount: (state, action: PayloadAction<number>) => {
      state.dailyUpdateCount = action.payload;
    },
    feedSuccess: (state, action: PayloadAction<DailyUpdate[]>) => {
      state.data = action.payload;
    },
    setComments: (state, action: PayloadAction<any>) => {
      state.comments = action.payload;
    },
    updateCommentInput: (state, action: PayloadAction<{ id: string, value: string }>) => {
      console.log(state, action);
      // state.comments[action.payload.id] = action.payload.value;
    },
    setErrors: (state, action: PayloadAction<boolean>) => {
      state.hasErrors = action.payload;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    finishLoading: (state) => {
      state.loading = false;
    },
    clearData: () => initialState,
  },
});

const feedReducer = feedSlice.reducer;
export default feedReducer;
