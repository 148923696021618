import React, { Suspense } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider } from 'notistack';

import ProjectDatasetsContainer from './Pages/Projects/ProjectPages/Datasets/DatasetsContainer';
import ProjectOverviewContainer from './Pages/Projects/ProjectPages/Overview/OverviewContainer';
import ProjectMonitoringContainer from './Pages/Projects/ProjectPages/Monitoring/MonitoringContainer';
import ProjectInfrastructureContainer from './Pages/Projects/ProjectPages/Infrastructure/InfrastructureContainer';
import ProjectReportsContainer from './Pages/Projects/ProjectPages/Reports/ReportsContainer';
import ProjectCodeContainer from './Pages/Projects/ProjectPages/Code/CodeContainer';
import DismissAction from './components/DismissAction/DismissAction';

import { PrivateRoute, PublicRoute } from './router/components';

import './App.scss';
import { DailyUpdatesContainer } from './Pages/Projects/ProjectPages/DailyUpdates/DailyUpdatesContainer';
import Settings from './Pages/Settings/Settings';

const AnalysisContainer = React.lazy(
  () => import('./Pages/Projects/ProjectPages/Analysis/AnalysisContainer'),
);

const IntegrationPage = React.lazy(
  () => import('./Pages/Projects/ProjectPages/Analysis/EmbedPage'),
);

const SettingsContainer = React.lazy(
  () => import('./Pages/Projects/ProjectPages/Settings/SettingsContainer'),
);

const ProjectsContainer = React.lazy(
  () => import('./Pages/Projects/ProjectsContainer'),
);
const ProjectContainer = React.lazy(
  () => import('./Pages/Projects/ProjectPages/ProjectContainer'),
);
const ProjectExperimentsContainer = React.lazy(
  () => import('./Pages/Projects/ProjectPages/Experiments/ExperimentsContainer'),
);
const MainContainer = React.lazy(() => import('./Pages/Main/MainContainer'));
const LoginContainer = React.lazy(() => import('./Pages/Login/LoginContainer'));
const UserContainer = React.lazy(() => import('./Pages/UserProfile/UserProfileContainer'));

function App() {
  return (
    <div className="App">
      <SnackbarProvider autoHideDuration={4000} action={DismissAction}>
        <BrowserRouter>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route element={<MainContainer />} />
              <Route
                path="main"
                element={(
                  <Suspense
                    fallback={(
                      <Backdrop open>
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    )}
                  >
                    <MainContainer />
                  </Suspense>
                )}
              >

                <Route index element={<UserContainer />} />
                <Route path="settings" element={<Settings />} />
                <Route path="projects" element={<ProjectsContainer />} />
                <Route path="monitoring" element={<div>Monitoring</div>} />
                <Route path="experiments" element={<div>Experiments</div>} />
                <Route path="datasets" element={<div>Datasets</div>} />
                <Route
                  path="infrastructure"
                  element={<div>Infrastructure</div>}
                />
                <Route path="code" element={<div>Code</div>} />
                <Route path="reports" element={<div>Reports</div>} />
                <Route path="user-profile" element={<UserContainer />} />
              </Route>
              <Route path="project">
                <Route
                  path=":projectId"
                  element={(
                    <Suspense
                      fallback={(
                        <Backdrop open>
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      )}
                    >
                      <ProjectContainer />
                    </Suspense>
                  )}
                >
                  <Route
                    index
                    element={(
                      <Suspense
                        fallback={(
                          <Backdrop open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        )}
                      >
                        <ProjectOverviewContainer />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="daily-updates"
                    element={(
                      <Suspense
                        fallback={(
                          <Backdrop open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        )}
                      >
                        <DailyUpdatesContainer />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="overview"
                    element={(
                      <Suspense
                        fallback={(
                          <Backdrop open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        )}
                      >
                        <ProjectOverviewContainer />
                      </Suspense>
                    )}
                  />

                  <Route
                    path="monitoring"
                    element={(
                      <Suspense
                        fallback={(
                          <Backdrop open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        )}
                      >
                        <ProjectMonitoringContainer />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="analysis"
                    element={(
                      <Suspense
                        fallback={(
                          <Backdrop open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                                )}
                      >
                        <AnalysisContainer />
                      </Suspense>
                        )}
                  />
                  <Route
                    path="analysis/:integrationId"
                    element={(
                      <Suspense
                        fallback={(
                          <Backdrop open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                                )}
                      >
                        <IntegrationPage />
                      </Suspense>
                        )}
                  />
                  <Route
                    path="settings"
                    element={(
                      <Suspense
                        fallback={(
                          <Backdrop open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                                )}
                      >
                        <SettingsContainer />
                      </Suspense>
                        )}
                  />
                  <Route
                    path="experiments"
                    element={(
                      <Suspense
                        fallback={(
                          <Backdrop open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        )}
                      >
                        <ProjectExperimentsContainer />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="datasets"
                    element={(
                      <Suspense
                        fallback={(
                          <Backdrop open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        )}
                      >
                        <ProjectDatasetsContainer />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="infrastructure"
                    element={(
                      <Suspense
                        fallback={(
                          <Backdrop open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        )}
                      >
                        <ProjectInfrastructureContainer />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="code"
                    element={(
                      <Suspense
                        fallback={(
                          <Backdrop open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        )}
                      >
                        <ProjectCodeContainer />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="reports"
                    element={(
                      <Suspense
                        fallback={(
                          <Backdrop open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        )}
                      >
                        <ProjectReportsContainer />
                      </Suspense>
                    )}
                  />
                </Route>
              </Route>
            </Route>

            <Route path="login" element={<PublicRoute />}>
              <Route
                index
                element={(
                  <Suspense
                    fallback={(
                      <Backdrop open>
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    )}
                  >
                    <LoginContainer />
                  </Suspense>
                )}
              />
            </Route>

            <Route path="*" element={<Navigate to="login" replace />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </div>
  );
}

export default App;
