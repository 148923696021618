import React, { useState, useEffect } from 'react';
import Loader from '../../../../../components/Loader/Loader';
import { IExperiment } from '../../../../../types/project/experiments';
import { StorageService } from '../../../../../core/services/storage/Storage.service';
import { IProject } from '../../../../../types/project/project';
import s from './Metrics.module.scss';
import EmbedContent from '../../../../../components/EmbedContent/EmbedContent';
import { ExperimentService } from '../../../../../core/services/projects/Experiment.service';
import StatusTag from '../../../../../components/StatusTag/StatusTag';

interface Props {
  data: IExperiment;
  projectData: IProject;
}

function Metrics({ data, projectData }: Props) {
  const [metricsInfo, setMetricsInfo] = useState<{ id: string, title: string, isImage: boolean, width: string, height: string }[]>([]);
  const [mainMetrics, setMainMetrics] = useState<any[]>([]);
  const [loading] = useState<boolean>(false);

  const fetchData = async () => {
    await Promise.all(
      [
        StorageService.getMetricsForExperiment(
          projectData.id,
          data.id,
        )
          .then((response) => {
            if (response.data.metrics) {
              setMetricsInfo(response.data.metrics.map((metric: any) => ({
                id: metric.id,
                title: metric.title,
                isImage: metric.isImage,
                height: metric.height,
                width: metric.width,
              })));
            }
          }),
        ExperimentService.getExperimentMetrics(
          projectData.id,
          data.id,
        ).then((response) => {
          if (response.data.metrics) {
            setMainMetrics(response.data.metrics);
          }
        }),
      ],
    );
  };

  useEffect(() => {
    fetchData();
    return () => {
      setMetricsInfo([]);
      setMainMetrics([]);
    };
  }, [data.id]);

  return (
    <div className={s.wrrapper}>
      {loading ? (<Loader />) : (
        <>
          <div style={{ marginBottom: '16px' }} className={s.config_line_data}>
            {mainMetrics.map((metric: any) => (
              <StatusTag
                key={metric?.id}
                usedValue={metric?.value}
                totalValue={2}
                displayName={metric?.displayName}
                width="100%"
                height="68px"
                type="2"
              />
            ))}
          </div>
          <EmbedContent projectId={projectData.id} entityId={data.id} embedInfo={metricsInfo} type="metrics" />
        </>
      )}
    </div>

  );
}

export default Metrics;
