import { IUser } from '../../../types/user/user';
import {
  destroy,
  get,
  patch,
  post,
  put,
} from '../apiClient';

interface SignInResponse {
  user: IUser
}

interface SignUpResponse {
  user: IUser
}

interface GetMeResponse {
  user: IUser
}

interface SignInRequestBody {
  email: string;
  password: string;
}

interface SignUpRequestBody {
  name: string;
  email: string;
  password: string;
}

interface UpdateMeRequestBody {
  name: string;
}

export const UserService = {
  signIn: (requestData: SignInRequestBody) => post<SignInResponse>('/auth/sign-in', requestData),
  signUp: (requestData: SignUpRequestBody) => post<SignUpResponse>('/auth/sign-up', requestData),
  signOut: () => post('/auth/sign-out'),
  getMe: () => get<GetMeResponse>('/user/me'),
  updateMe: (requestData: UpdateMeRequestBody) => patch('/user/me', requestData),
  uploadAvatar: (data: FormData) => put('/user/me/avatar', data),
  deleteAvatar: () => destroy('/user/me/avatar'),
};
