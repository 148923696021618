import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IExperiment } from '../../../../types/project/experiments';
import { IRecently } from '../../../../types/project/project';

export type ExperimentsState = {
  loading: boolean;
  hasErrors: boolean;
  fetching: boolean;
  currentPage: number;
  totalCount: number | undefined;
  experiments: IExperiment[];
};
//
export const initialState: ExperimentsState = {
  loading: false,
  hasErrors: false,
  fetching: false,
  currentPage: 1,
  totalCount: undefined,
  experiments: [],
};
// A slice
export const experimentSlice = createSlice({
  name: 'projectExperiments',
  initialState,
  reducers: {
    setExperiments: (state, action: PayloadAction<any>) => {
      const { data, recentlyItems } = action.payload;
      data.forEach((key: IExperiment) => {
        const recently = recentlyItems && recentlyItems
          .filter((item: IRecently) => item.category === 'experiments')
          .find((item: IRecently) => item.id === key.id);
        data.checked = recently?.check || false;
      });

      state.experiments = [...state.experiments, ...data];
    },
    startLoading: (state) => {
      state.loading = true;
    },
    finishLoading: (state) => {
      state.loading = false;
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },
    setCheckAllExperiments: (state, action: PayloadAction<boolean>) => {
      state.experiments.forEach((experiment) => {
        experiment.checked = action.payload;
      });
    },
    setCheckExperiment: (state, action: PayloadAction<string>) => {
      const id = action.payload;

      state.experiments.forEach((experiment) => {
        if (experiment.id === id) {
          experiment.checked = !experiment.checked;
        }
      });
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setErrors: (state, action: PayloadAction<boolean>) => {
      state.hasErrors = action.payload;
    },
    clearData: () => initialState,
  },
});

const projectExperimentReducer = experimentSlice.reducer;
export default projectExperimentReducer;
