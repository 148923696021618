import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import s from './UserProfile.module.scss';
import { getMe } from '../../core/redux/user/actions';
import { getUserInfo } from '../../core/redux/user/selectors';
import { useAppDispatch } from '../../core/redux/store';
import { env } from '../../env';

function UserProfile() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMe());
  }, []);

  const user = useSelector(getUserInfo);

  return (
    <div className={s.wrapper}>
      <div className={s.submenu}>
        <img alt="submenu" src="/images/icons/Submenu.svg" />
      </div>
      <div className={s.user_container}>
        <Avatar
          alt={user?.name}
          src={`${env.REACT_APP_API_STORAGE_URL}/attachment/user/${user.id}/avatar`}
          sx={{ width: 50, height: 50, cursor: 'pointer' }}
          onClick={() => navigate('/main/user-profile')}
        />
      </div>
    </div>
  );
}

export default UserProfile;
