import api from '../../api';

export const InfrastructureService = {
  getProjectInfrastructure: (
    projectId: string,
    display?: boolean,
    page?: number,
    limit?: number,
    signal?: AbortSignal,
    cancelToken?: any,
  ) => api(
    'GET',
    [],
    `/view/projects/${projectId}/infrastructure?page=${page}&limit=${limit}`,
    {},
    signal,
    cancelToken,
  ),
};
