import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Box, Button, FormControl, Modal, Typography, TextField,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { oneProjectData } from '../../../../core/redux/projects/selectors';
import { feedRequest, addNewDailyUpdate } from '../../../../core/redux/projects/feeds/actions';
import { useAppDispatch } from '../../../../core/redux/store';
import FileInput from '../../../../components/FileInput/FileInput';

interface Props {
  isModalTriggered: boolean;
  closeTriggered: () => void;
}

export function AddNewDailyUpdateModal({ isModalTriggered, closeTriggered }: Props) {
  const dispatch = useAppDispatch();
  const projectData = useSelector(oneProjectData);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (projectData) {
      dispatch(feedRequest(projectData.id, 1, 10));
    }
  }, [projectData]);

  useEffect(() => {
    setOpen(isModalTriggered);
  }, [isModalTriggered]);

  const handleClose = () => {
    closeTriggered();
    setOpen(false);
  };

  const theme = useMemo(() => createTheme({
    palette: {
      mode: 'dark',
    },
  }), []);
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [file, setFile] = React.useState({} as File);
  const [text, setText] = React.useState('');

  const clearModal = () => {
    setText('');
    setFile({} as File);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files?.length) return;
    setFile(event?.target?.files?.[0]);
  };

  const handleNewDailyUpdateText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setText(newText);
  };

  const addNewDailyUpdateAction = () => {
    const formData = new FormData();
    if (file?.name) {
      formData.append('file', file, file.name);
    }

    dispatch(addNewDailyUpdate(projectData.id, { text }, { data: formData, needSendImage: Boolean(file?.name) }));

    setTimeout(() => {
      dispatch(feedRequest(projectData.id, 1, 10));
    }, 2000);

    clearModal();

    closeTriggered();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box sx={style}>
        <Typography color={theme.palette.primary.light} id="modal-modal-title" variant="h6" component="h3">
          Add daily update
        </Typography>

        <Box mt={2} sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-static"
              label="Put your text here"
              multiline
              rows={6}
              onChange={handleNewDailyUpdateText}
            />
            <Button style={{ marginTop: '10px' }} component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
              <FileInput handleChange={handleImageUpload}>
                Upload image
              </FileInput>
            </Button>

            <Button variant="contained" style={{ marginTop: '10px' }} onClick={addNewDailyUpdateAction}>
              Add daily update
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Modal>
  );
}
