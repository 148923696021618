import React, { useState, useEffect, useMemo } from 'react';
import { Stack, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import fileDownload from 'js-file-download';
import axios from 'axios';
import GeneralInfo from '../../../../../components/DatasetComponents/GeneralInfo/GeneralInfo';
import StatisticProperties from '../../../../../components/DatasetComponents/StatisticProperties/StatisticProperties';
import Outliers from '../../../../../components/DatasetComponents/Outliers/Outliers';
import s from './Dataset.module.scss';
import {
  IExpandDataset,
  ITagsData,
} from '../../../../../types/project/datasets';
import { env } from '../../../../../env';

export interface Props {
  expandData: IExpandDataset;
  datasetInStorage?: any;
}

function Dataset({ expandData, datasetInStorage }: Props) {
  const [tagsData, setTagsData] = useState<undefined | ITagsData[]>();

  const getTagsData = () => {
    if (expandData) {
      const { generalInformation } = expandData.statistics;
      const tags: ITagsData[] = [
        {
          value: generalInformation?.total_number_of_samples,
          displayName: 'samples',
        },
        {
          value: generalInformation?.total_number_of_columns,
          displayName: 'columns',
        },
        {
          value: generalInformation?.storage_size,
          displayName: generalInformation?.storage_unit,
        },
      ];
      setTagsData(tags);
    }
  };

  const theme = useMemo(() => createTheme({
    palette: {
      mode: 'dark',
    },
  }), []);

  useEffect(() => {
    getTagsData();
  }, [expandData]);

  const handleDownload = (url: string, filename: string) => {
    axios.get(url, {
      responseType: 'blob',
      withCredentials: true,
    })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const getFileName = () => {
    const extension = datasetInStorage?.path.split('/models/')[1].split('.')[1];
    const projectId = datasetInStorage?.projectId.split('-').join('');
    const experimentId = datasetInStorage?.experimentId.split('-').join('');
    return `model:${projectId}:${experimentId}.${extension}`;
  };

  const getPath = () => `${env.REACT_APP_API_STORAGE_URL}/attachment/models/${datasetInStorage.projectId}/${datasetInStorage.experimentId}/${datasetInStorage.id}`;

  return (
    <ThemeProvider theme={theme}>
      <Stack alignItems="flex-end" direction="row" justifyContent="space-between" style={{ marginBottom: '14px' }}>
        <div style={{ marginRight: '20px' }} className={s.title}>General information</div>
        {datasetInStorage?.id && (
          <div>
            <Button startIcon={<DownloadIcon />} size="small" variant="outlined" onClick={() => handleDownload(getPath(), getFileName())}>
              Download model
            </Button>
          </div>
        )}
      </Stack>
      {tagsData && (
        <GeneralInfo
          displayName={expandData.displayName}
          version={expandData.version}
          tagsData={tagsData}
        />
      )}
      <div className={s.statistic}>
        {expandData?.statistics?.statisticProperties && (
          <StatisticProperties
            data={expandData.statistics.statisticProperties}
          />
        )}
      </div>
      {expandData.statistics?.outliers && <Outliers data={expandData.statistics.outliers} />}
    </ThemeProvider>
  );
}

export default Dataset;
