import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUser } from '../../../types/user/user';

export type InitialState = {
  loading: boolean;
  error: null | string;
  isUserHasAccess: boolean;
  id: string;
  name: string;
  email: string;
  image: string;
};

export const initialState: InitialState = {
  loading: false,
  error: null,
  isUserHasAccess: false,
  id: '',
  name: '',
  email: '',
  image: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.error = null;
      state.loading = true;
    },
    finishLoading: (state) => {
      state.loading = false;
    },
    setUserSignIn: (state, action: PayloadAction<IUser & Partial<InitialState>>) => {
      state.error = null;
      state.isUserHasAccess = true;
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.image = action.payload.image;
    },
    setUserSignUp: (state, action: PayloadAction<IUser & Partial<InitialState>>) => {
      state.error = null;
      state.isUserHasAccess = true;
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.image = action.payload.image;
    },
    setSignOut: (state) => {
      state.error = null;
      state.isUserHasAccess = false;
      state.id = '';
      state.name = '';
      state.email = '';
      state.image = '';
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.error = null;
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.image = action.payload.image;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

const userReducer = userSlice.reducer;
export default userReducer;
