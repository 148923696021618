import monitoringApi from '../../monitoringApi';
import { getAdditionalParams } from '../../helpers/apiHelpers';

export const MonitoringService = {
  getProjectMonitoring: (
    projectId: string,
    page: number,
    size: number,
  ) => monitoringApi(
    'GET',
    [],
    `/monitoring/${projectId}/actual?page=${page}&limit=${size}`,
    {},
  ),
  getMonitoring: (version: string, display?: boolean) => monitoringApi('GET', [], `/monitoring/${version}${getAdditionalParams(display)}`, {}),
  getMonitoringThresholds: (projectId: string) => monitoringApi('GET', [], `/monitoring/${projectId}/thresholds`, {}),
  getMonitoringScheme: (projectId: string) => monitoringApi('GET', [], `/monitoring/${projectId}/raw/scheme`, {}),
  addMonitoringThreshold: (projectId: string, data: any) => monitoringApi('POST', data, `/monitoring/${projectId}/thresholds`, {}),
  deleteMonitoringThreshold: (projectId: string, thresholdId: string) => monitoringApi('DELETE', [], `/monitoring/${projectId}/thresholds/${thresholdId}`, {}),
};

// Project Monitoring
// GET /projects/<project_version>/monitoring
// GET /projects/<project_version>/monitoring/<monitoring_version>
