import api from '../../api';

export const OverviewService = {
  getProjectOverview: (
    projectId: string,
    signal: AbortSignal,
  ) => api(
    'GET',
    [],
    `/view/projects/${projectId}/overview`,
    {},
    signal,
  ),
  removeUser: (
    projectId: string,
    userId: string,
  ) => api(
    'DELETE',
    [],
    `/projects/${projectId}/user/${userId}`,
    {},
  ),
  getUsersInProject: (projectId: string) => api(
    'GET',
    [],
    `/projects/${projectId}/users`,
    {},
  ),
  addUser: (
    projectId: string,
    userId: string,
  ) => api(
    'POST',
    [],
    `/projects/${projectId}/user/${userId}`,
    {},
  ),
  fetchUser: () => api('GET', [], '/user'),
};
