import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMonitoringItems } from '../../../../types/project/monitoring';

export type MonitoringState = {
  loading: boolean;
  hasErrors: boolean;
  fetching: boolean;
  currentPage: number;
  totalCount: number | undefined;
  data: IMonitoringItems;
};
//
export const initialState: MonitoringState = {
  loading: false,
  hasErrors: false,
  fetching: false,
  currentPage: 1,
  totalCount: undefined,
  data: {
    monitoringInfo: [],
  },
};
// A slice
export const monitoringSlice = createSlice({
  name: 'projectMonitoring',
  initialState,
  reducers: {
    setMonitoring: (state, action: PayloadAction<any>) => {
      const data = action.payload;
      data.monitoringInfo.forEach((monitoringItem: any) => {
        monitoringItem.checked = false;
      });
      state.data.monitoringInfo = [...state.data.monitoringInfo, ...action.payload.monitoringInfo];
    },
    startLoading: (state) => {
      state.loading = true;
    },
    finishLoading: (state) => {
      state.loading = false;
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },
    setCheckAllMonitoring: (state, action: PayloadAction<boolean>) => {
      state.data.monitoringInfo.forEach((monitoring) => {
        monitoring.checked = action.payload;
      });
    },
    setCheckMonitoring: (state, action: PayloadAction<string>) => {
      const id = action.payload;

      const monitoring = state.data.monitoringInfo.find((monitoringItem) => monitoringItem.id === id);
      if (monitoring) {
        monitoring.checked = !monitoring.checked;
      }
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setErrors: (state, action: PayloadAction<boolean>) => {
      state.hasErrors = action.payload;
    },
    clearData: () => initialState,
  },
});

const projectMonitoringSliceReducer = monitoringSlice.reducer;
export default projectMonitoringSliceReducer;
