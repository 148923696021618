import React, { useEffect, useState } from 'react';
import { IExperiment } from '../../../../../types/project/experiments';
import { IProject } from '../../../../../types/project/project';
import s from './Artifacts.module.scss';
import { StorageService } from '../../../../../core/services/storage/Storage.service';
import Loader from '../../../../../components/Loader/Loader';
import EmbedContent from '../../../../../components/EmbedContent/EmbedContent';

interface Props {
  data: IExperiment;
  projectData: IProject;
}

function Artifacts({ data, projectData }: Props) {
  const [artifactsInfo, setArtifactsInfo] = useState<{ id: string, title: string, isImage: boolean, height: string, width: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setLoading(true);
    const res = await StorageService.getArtifactsForExperiment(
      projectData.id,
      data.id,
    );

    if (res.data.artifacts) {
      setArtifactsInfo(res.data.artifacts.map((artifact: any) => ({
        id: artifact.id,
        title: artifact.title,
        isImage: artifact.isImage,
        height: artifact.height,
        width: artifact.width,
        fileType: artifact.fileType,
        path: artifact.path,
      })));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setArtifactsInfo([]);
    };
  }, [data.id]);

  return (
    <div className={s.wrrapper}>
      {loading ? (<Loader />) : (
        <EmbedContent projectId={projectData.id} entityId={data.id} embedInfo={artifactsInfo} type="artifacts" />
      )}
    </div>

  );
}

export default Artifacts;
