import React from 'react';
import StatusTag from '../../../../../components/StatusTag/StatusTag';
import ToBeImpelemented from '../../../../../components/ToBeImpelemented/ToBeImpelemented';
import { IMonitoring } from '../../../../../types/project/monitoring';
import s from './Overview.module.scss';

interface Props {
  data: IMonitoring;
}

function Overview({ data }: Props) {
  return (
    <div className={s.wrapper}>
      <div className={s.title}>Name</div>
      <ToBeImpelemented
        element={(
          <div className={s.name}>
            {data.name}
            {' '}
            -
            {' '}
            {data.model}
          </div>
)}
        color="primary"
      />
      <div className={s.config_line}>
        <div className={s.title}>Metrics</div>
        <div className={s.config_line_data}>
          <ToBeImpelemented
            element={
              data.metrics?.map((metric: any) => (
                <StatusTag
                  key={`${metric.displayName}`}
                  usedValue={metric.value}
                  totalValue={2}
                  displayName={metric.displayName}
                  thresholdColor={metric?.thresholdColor}
                  width="100%"
                  height="68px"
                  type="2"
                />
              ))
            }
            color="primary"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
}

export default Overview;
