/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from 'react';
import {
  Typography,
  Button,
  Box,
  FormControl,
  Modal,
  TextField,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useAppDispatch } from '../../../../core/redux/store';

import { addNewCommentRequest } from '../../../../core/redux/projects/feeds/actions';

interface Props {
  dailyUpdateId: string;
  isModalTriggered: boolean;
  closeTriggered: () => void;
}

export function AddNewCommentModal({ dailyUpdateId, isModalTriggered, closeTriggered }: Props) {
  const dispatch = useAppDispatch();

  const theme = useMemo(() => createTheme({
    palette: {
      mode: 'dark',
    },
  }), []);

  const [open, setOpen] = React.useState(false);
  const [newComment, setNewComment] = React.useState('');
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    setOpen(isModalTriggered);
  }, [isModalTriggered]);

  const handleClose = () => {
    closeTriggered();
    setOpen(false);
  };

  const addNewCommentAction = () => {
    dispatch(addNewCommentRequest(dailyUpdateId, newComment));
    closeTriggered();
    setOpen(false);
  };

  const handleNewCommentText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography color={theme.palette.primary.light} id="modal-modal-title" variant="h6" component="h3">
          Add comment
        </Typography>

        <Box mt={2} sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-static"
              label="Put your comment here"
              multiline
              rows={6}
              onChange={handleNewCommentText}
            />

            <Button onClick={addNewCommentAction}>
              Add comment
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Modal>
  );
}
