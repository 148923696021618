import { AppDispatch, AppThunk } from '../store';
import { userSlice } from './reducer';

import { UserService } from '../../services/user/User.service';
import { StorageService } from '../../services/storage/Storage.service';

const {
  startLoading,
  finishLoading,
  setUserSignIn,
  setError,
  setSignOut,
} = userSlice.actions;

export const signIn = (email: string, password: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());

    const { data: { user } } = await UserService.signIn({ email, password });

    dispatch(setUserSignIn(user));
  } catch (e) {
    dispatch(setError((e as Error).message));
  } finally {
    dispatch(finishLoading());
  }
};

export const signOut = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());

    await UserService.signOut();

    dispatch(setSignOut());
  } catch (e) {
    dispatch(setError((e as Error).message));
  } finally {
    dispatch(finishLoading());
  }
};

export const signUp = (name: string, email: string, password: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());

    const { data: { user } } = await UserService.signUp({ name, email, password });

    dispatch(setUserSignIn(user));
  } catch (e) {
    dispatch(setError((e as Error).message));
  } finally {
    dispatch(finishLoading());
  }
};

export const getMe = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());

    const { data: { user } } = await UserService.getMe();

    dispatch(setUserSignIn(user));
  } catch (e) {
    dispatch(setError((e as Error).message));
  } finally {
    dispatch(finishLoading());
  }
};

export const updateMe = (data: { name: string }): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());

    const { data: { user } } = await UserService.updateMe(data);

    dispatch(setUserSignIn(user));
  } catch (e) {
    dispatch(setError((e as Error).message));
  } finally {
    dispatch(finishLoading());
  }
};

export const uploadAvatar = (data: FormData): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());

    await StorageService.uploadUserAvatar(data);
  } catch (e) {
    dispatch(setError((e as Error).message));
  } finally {
    dispatch(finishLoading());
  }
};

export const deleteAvatar = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());

    await UserService.deleteAvatar();
  } catch (e) {
    dispatch(setError((e as Error).message));
  } finally {
    dispatch(finishLoading());
  }
};
