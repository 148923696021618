import React, { useState, useEffect } from 'react';
import Loader from '../../../../../components/Loader/Loader';
import { StorageService } from '../../../../../core/services/storage/Storage.service';
import s from './ConceptDrift.module.scss';
import EmbedContent from '../../../../../components/EmbedContent/EmbedContent';

interface Props {
  data: {
    projectId: string;
    id: string;
  };
}

function ConceptDrift({ data }: Props) {
  const [monitoringInfo, setMonitoringInfo] = useState<{ id: string, title: string, isImage: boolean, width: string, height: string }[]>([]);
  const [loading] = useState<boolean>(false);

  const fetchData = async () => {
    await StorageService.getConceptDriftForMonitoring(
      data.projectId,
      data.id,
    )

      .then((response) => {
        if (response.data.monitoring) {
          setMonitoringInfo(response.data.monitoring.map((monitoring: any) => ({
            id: monitoring.id,
            title: monitoring.title,
            isImage: monitoring.isImage,
            height: monitoring.height,
            width: monitoring.width,
          })));
        }
      });
  };

  useEffect(() => {
    fetchData();
    return () => {
      setMonitoringInfo([]);
    };
  }, [data.id]);

  return (
    <div className={s.wrrapper}>
      {loading ? (<Loader />) : (
        <EmbedContent projectId={data.projectId} entityId={data.id} embedInfo={monitoringInfo} type="monitoring" />
      )}
    </div>

  );
}

export default ConceptDrift;
