import React, { useEffect, useMemo, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Table, TableCell, TableContainer, TableRow,
  TableBody,
  TableHead,
  Paper,
  Container, Typography, Button, Stack,
} from '@mui/material';
import { StorageService } from '../../../core/services/storage/Storage.service';
import StorageSettingsItem from './StorageSettingsItem';
import { AddNewStorageSettingTokenModal } from './AddNewStorageSettingTokenModal';

function Settings() {
  const [settings, setSettings] = useState(
    [] as {
      id: string;
      token: string;
      isActive: boolean;
      needToExpire: boolean;
      expiresAt: string;
      projectsInfo: { id: string; name: string }[];
      createdDate: string;
      updatedDate: string;
    }[],
  );
  const theme = useMemo(() => createTheme({
    palette: {
      mode: 'dark',
    },
  }), []);

  const [openComment, setOpenComment] = React.useState(false);
  const isAddNewCommentModalClosed = () => {
    setOpenComment(false);
    StorageService.getStorageSettings()
      .then((data) => {
        setSettings(data);
      });
  };

  useEffect(() => {
    StorageService.getStorageSettings()
      .then((data) => {
        setSettings(data);
      });
  }, []);

  const generateNewTokenHandler = () => {
    setOpenComment(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container style={{ marginTop: '20px' }}>
        <Stack justifyContent="space-between" direction="row" spacing={2}>
          <Typography variant="h5" color={theme.palette.primary.light} style={{ marginBottom: '10px' }}>Storage tokens</Typography>
          <Button variant="contained" onClick={() => generateNewTokenHandler()}>
            Generate new token
          </Button>
        </Stack>

        <TableContainer style={{ marginTop: '20px' }} component={Paper}>
          {settings?.length ? (
            <Table sx={{ minWidth: 1000 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Token</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Create date</TableCell>
                  <TableCell>Need to expire</TableCell>
                  <TableCell>Expire at</TableCell>
                  <TableCell>Projects</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {settings.map((row, index) => (
                  <StorageSettingsItem item={row} index={index} />
                ))}
              </TableBody>
            </Table>
          ) : <span />}
        </TableContainer>
      </Container>
      <AddNewStorageSettingTokenModal isModalTriggered={openComment} closeTriggered={isAddNewCommentModalClosed} />
    </ThemeProvider>
  );
}

export default Settings;
