import api from '../../api';

export const DailyUpdateService = {
  getDailyUpdate: (
    projectId: string,
    page?: number,
    limit?: number,
    signal?: AbortSignal,
    cancelToken?: any,
  ) => api(
    'GET',
    [],
    `/daily-updates/projects/${projectId}?page=${page}&limit=${limit}`,
    {},
    signal,
    cancelToken,
  ),
  createDailyUpdate: (
    projectId: string,
    dailyUpdate: { text: string },

  ) => api(
    'POST',
    dailyUpdate,
    `/daily-updates/projects/${projectId}`,
  ),
  createComment: (
    dailyUpdateId: string,
    text: string,
    cancelToken?: any,
  ) => api(
    'POST',
    {
      text,
    },
    `/daily-updates/${dailyUpdateId}/comments`,
    cancelToken,
  ),
  getComments: (
    dailyUpdateId: string,
    signal?: AbortSignal,
    cancelToken?: any,
  ) => api(
    'GET',
    [],
    `/daily-updates/${dailyUpdateId}/comments`,
    {},
    signal,
    cancelToken,
  ),
};
