import React from 'react';
import {
  TableCell, TableRow,
} from '@mui/material';
import { getFormattedDateFromTimeStamp } from '../../../core/helpers/dateMethods';
import { StorageService } from '../../../core/services/storage/Storage.service';

type Props = {
  item: {
    id: string;
    token: string;
    isActive: boolean;
    needToExpire: boolean;
    expiresAt: string;
    projectsInfo: { id: string; name: string }[];
    createdDate: string;
  },
  index: number;
};

function Settings({ item, index }: Props) {
  const [unhidToken, setUnhidToken] = React.useState('');

  const getTokenInfoById = () => {
    if (unhidToken) return;
    StorageService.getStorageSetting(item.id)
      .then((data) => {
        setUnhidToken(data.token.token);
      });
  };

  return (
    <TableRow
      key={item.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        {index + 1}
      </TableCell>
      <TableCell onClick={getTokenInfoById}>
        {unhidToken || item.token}
      </TableCell>
      <TableCell>{item.isActive ? 'Active' : 'Inactive'}</TableCell>
      <TableCell>{getFormattedDateFromTimeStamp(item.createdDate)}</TableCell>
      <TableCell>{item.needToExpire ? 'Yes' : 'Not'}</TableCell>
      <TableCell>{getFormattedDateFromTimeStamp(item.expiresAt)}</TableCell>
      <TableCell>{item.projectsInfo.map((info) => info.name).join(', ')}</TableCell>
    </TableRow>
  );
}

export default Settings;
