import React from 'react';
import { useSelector } from 'react-redux';

import { Navigate, Outlet } from 'react-router-dom';
import { getUserInfo } from '../../../core/redux/user/selectors';

export function PublicRoute() {
  const mainUrl = '/main';
  const { isUserHasAccess } = useSelector(getUserInfo);

  if (!isUserHasAccess) {
    return <Outlet />;
  }

  return <Navigate to={mainUrl} />;
}
