import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProjectOverview } from '../../../../types/project/project';

export type OverviewState = {
  loading: boolean;
  hasErrors: boolean;
  data: IProjectOverview | null;
  usersForAccess: {
    id: string;
    name: string;
    email: string;
  }[]
  usersInProject: {
    id: string;
    name: string;
    email: string;
    image: string;
  }[]
};
//
export const initialState: OverviewState = {
  loading: false,
  hasErrors: false,
  data: null,
  usersForAccess: [],
  usersInProject: [],
};
// A slice
export const overviewSlice = createSlice({
  name: 'projectOverview',
  initialState,
  reducers: {
    setOverview: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setUsers: (state, action: PayloadAction<any>) => {
      state.usersForAccess = action.payload;
    },
    setUsersInProject: (state, action: PayloadAction<any>) => {
      state.usersInProject = action.payload;
    },
    setErrors: (state, action: PayloadAction<boolean>) => {
      state.hasErrors = action.payload;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    finishLoading: (state) => {
      state.loading = false;
    },
    clearData: () => initialState,
  },
});

const projectOverviewReducer = overviewSlice.reducer;
export default projectOverviewReducer;
