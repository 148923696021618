declare global {
  interface Window {
    env: any
  }
}

// change with your own variables
type EnvType = {
  REACT_APP_API_URL: string,
  REACT_APP_API_STORAGE_URL: string,
  REACT_APP_API_MONITORING_URL: string,
};

// window.env.REACT_APP_API_URL = 'http://localhost:4000/api/v1';
// window.env.REACT_APP_API_STORAGE_URL = 'http://localhost:5000/api/v1';

export const env: EnvType = { ...process.env, ...window.env };
