import React from 'react';
import styled from '@emotion/styled';

interface Props {
  children?: React.ReactNode;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function FileInput({ children, handleChange }: Props) {
  return (
    <>
      {children}
      <VisuallyHiddenInput type="file" onChange={handleChange} />
    </>
  );
}

export default FileInput;
