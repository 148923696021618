import React, { useState, useEffect } from 'react';
import { IExperiment } from '../../../../../types/project/experiments';
import { ExperimentService } from '../../../../../core/services/projects/Experiment.service';
import Loader from '../../../../../components/Loader/Loader';
import s from './Dataset.module.scss';
import Dataset from './Dataset';
import { IProject } from '../../../../../types/project/project';
import { IExpandDataset } from '../../../../../types/project/datasets';
import { StorageService } from '../../../../../core/services/storage/Storage.service';

export interface Props {
  data: IExperiment;
  projectData: IProject;
}

function DatasetContainer({ data, projectData }: Props) {
  const [expandData, setExpandData] = useState<
  undefined | IExpandDataset
  >();

  const [loading, setLoading] = useState(false);
  const [datasetInStorage, setDatasetInStorage] = useState<[]>();

  const fetchExpandData = async () => {
    try {
      setLoading(true);

      StorageService.getModelsForExperiment(projectData.id, data.id)
        .then((dataset) => {
          if (dataset.data.models.length > 0) {
            setDatasetInStorage(dataset.data.models[0]);
          }
        });

      const response = await ExperimentService.getExperimentDatasets(
        projectData.id,
        data.id,
      );

      setExpandData(response.data.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExpandData();
    return () => {
      setExpandData(undefined);
    };
  }, [data.id]);

  return (
    <div className={s.wrapper}>
      {loading ? (
        <Loader />
      ) : (
        expandData && expandData.statistics
        && <Dataset datasetInStorage={datasetInStorage} key={expandData.id} expandData={expandData} />
      )}
    </div>
  );
}

export default DatasetContainer;
