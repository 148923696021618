import {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
} from 'axios';

type AxiosErrorWithMessage = AxiosError<{ error: string }>;

const onResponse = (response: AxiosResponse): AxiosResponse => response;
const onResponseError = (error: AxiosErrorWithMessage) => {
  const { response, message } = error;

  if (response?.status === 401 || response?.status === 403) {
    // eslint-disable-next-line no-console
    console.error(response.status, message);
  }

  return Promise.reject(error);
};

export function setupInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  return axiosInstance;
}
