import api from '../../api';

export const ProjectService = {
  getAllProjects: (page: number, limit: number, search?: string) => api('GET', [], `/view/projects?page=${page}&limit=${limit}&search=${search || ''}`),
  getProjectsShortInfo: () => api('GET', [], '/view/projects/short'),
  getOneProject: (id: string, signal?: AbortSignal) => api('GET', [], `/projects/${id}`, {}, signal),
  getProjectOverview: (version: string, display: boolean, signal: any) => api(
    'GET',
    [],
    `/projects/${version}/overview${
      display ? '?display=true' : '?display=false'
    }`,
    {},
    signal,
  ),
};

// Project
// GET /projects
// GET /projects/<_version>
