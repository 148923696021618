import { OverviewService } from '../../../services/projects/Overview.service';
import { AppThunk, AppDispatch } from '../../store';
import { overviewSlice } from './reducer';

const {
  setUsersInProject, setUsers, setOverview, startLoading, finishLoading, setErrors, clearData,
} = overviewSlice.actions;
const {
  getProjectOverview, removeUser, fetchUser, addUser, getUsersInProject,
} = OverviewService;

export const fetchUsersInProject = (projectId: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    const usersInProject = await getUsersInProject(projectId);
    dispatch(setUsersInProject(usersInProject.data.users));
  } catch (error) {
    console.log(error);
  }
};

export const addUserToProject = (projectId: string, userId: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    await addUser(projectId, userId);
    const usersInProject = await getUsersInProject(projectId);
    dispatch(setUsersInProject(usersInProject.data.users));
  } catch (error) {
    console.log(error);
  }
};

export const removeUserFromProject = (projectId: string, userId: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    await removeUser(projectId, userId);
    const usersInProject = await getUsersInProject(projectId);
    dispatch(setUsersInProject(usersInProject.data.users));
  } catch (error) {
    console.log(error);
  }
};

export const fetchUsers = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    const users = await fetchUser();
    dispatch(setUsers(users.data.users));
  } catch (error) {
    console.log(error);
  }
};

export const fetchOverview = (projectId: string, signal: AbortSignal): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const response = await getProjectOverview(projectId, signal);
    dispatch(setOverview(response.data.overview));
    dispatch(setErrors(false));
  } catch (e) {
    console.log(e);
    dispatch(setErrors(true));
  } finally {
    dispatch(finishLoading());
  }
};

export const clearOverview = () => (dispatch: AppDispatch) => {
  dispatch(clearData());
};
