import axios, { AxiosRequestConfig } from 'axios';
import { setupInterceptors } from './interceptors';
import { env } from '../../env';

class ApiClient {
  private readonly baseURL = `${env.REACT_APP_API_URL}`;

  private readonly defaultHeaders = {
    'Access-Control-Allow-Credentials': true,
    'Content-Type': 'application/json',
  };

  public readonly axiosClient = axios.create({
    baseURL: this.baseURL,
    headers: this.defaultHeaders,
    withCredentials: true,
  });

  public readonly apiRequest = async <Response>({
    url,
    method = 'GET',
    data = null,
    params = null,
    headers = {},
  }: {
    url: string;
    method?: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
  }): Promise<Response> => {
    const config: AxiosRequestConfig = {
      url,
      method,
      data,
      params,
      headers,
    };

    const response = await this.axiosClient.request<Response>(config);

    return response.data;
  };
}

const apiClient = new ApiClient();

export const { apiRequest } = apiClient;
export const {
  get,
  post,
  patch,
  put,
  delete: destroy,
} = setupInterceptors(apiClient.axiosClient);
